import React from 'react';

import { ETitleV2Theme, THEMES } from './constants';
import { LogosBlock } from './components/LogosBlock';

import type { ImageProps } from 'next/image';

export type TTitleV2Props = {
  eyebrow?: string;
  title?: React.JSX.Element;
  subTitle?: React.JSX.Element;
  theme?: ETitleV2Theme;
  hasEyebrowMarker?: boolean;
  eyebrowMarkerColor?: string;
  logosText?: string;
  logos: ImageProps[];
};

export const TitleV2: React.FC<TTitleV2Props> = ({
  eyebrow,
  hasEyebrowMarker,
  eyebrowMarkerColor,
  title,
  subTitle,
  logos,
  logosText,
  theme = ETitleV2Theme.Light,
}) => {
  const themeStyles = THEMES[theme] || THEMES[ETitleV2Theme.Light];

  return (
    <div className='flex flex-col gap-10'>
      {eyebrow ? (
        <div className='flex items-center gap-2.5 text-base font-normal leading-[24.8px] tracking-[0.32px] text-control-600'>
          {hasEyebrowMarker ? (
            <span
              className='size-2 shrink-0 rounded-full bg-[rgb(23,193,97)]'
              style={
                eyebrowMarkerColor
                  ? { backgroundColor: eyebrowMarkerColor }
                  : undefined
              }
            />
          ) : null}
          <span className={themeStyles.eyebrowColor}>{eyebrow}</span>
        </div>
      ) : null}

      <div className='flex flex-col gap-8 tablet:flex-row'>
        <div className='flex-1 text-5xl font-semibold leading-loose tablet:basis-1/2 tablet:text-8xl tablet:leading-snug'>
          <span className={themeStyles.titleColor}>{title}</span>
        </div>

        {subTitle ? (
          <div className='text-4xl font-semibold leading-relaxed tablet:hidden'>
            <span className={themeStyles.subTitleColor}>{subTitle}</span>
          </div>
        ) : null}

        <LogosBlock logos={logos} logosText={logosText} theme={theme} />
      </div>

      {subTitle ? (
        <div className='hidden max-w-[75%] text-4xl font-semibold leading-relaxed tablet:block'>
          <span className={themeStyles.subTitleColor}>{subTitle}</span>
        </div>
      ) : null}
    </div>
  );
};
