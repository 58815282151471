export enum ETitleV2Theme {
  Light = 'Light',
  Dark = 'Dark',
}

export enum EEyebrowMarker {
  No = 'No',
  Yes = 'Yes',
}

type TTitleV2Theme = {
  eyebrowColor: string;
  logosTextColor: string;
  logoBorderColor: string;
  titleColor: string;
  subTitleColor: string;
};

export const THEMES: Record<ETitleV2Theme, TTitleV2Theme> = {
  [ETitleV2Theme.Light]: {
    eyebrowColor: 'text-control-600',
    logosTextColor: 'text-control-600',
    logoBorderColor: 'border-interface-400',
    titleColor: 'text-interface-1000 tablet:text-control-950',
    subTitleColor: 'text-control-950',
  },
  [ETitleV2Theme.Dark]: {
    eyebrowColor: 'text-[rgb(161,163,173)]',
    logosTextColor: 'text-[rgb(113,116,122)]',
    logoBorderColor: 'border-[rgb(113,116,122)]',
    titleColor: 'text-interface-50',
    subTitleColor: 'text-interface-50',
  },
};
