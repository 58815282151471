import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React from 'react';

import { ETitleV2Theme, THEMES } from '../constants';

type TLogosBlockProps = {
  logosText?: string;
  logos: ImageProps[];
  theme?: ETitleV2Theme;
};

export const LogosBlock: React.FC<TLogosBlockProps> = ({
  logosText,
  logos,
  theme = ETitleV2Theme.Light,
}) => {
  if (!logosText && !logos?.length) {
    return null;
  }

  const themeStyles = THEMES[theme] || THEMES[ETitleV2Theme.Light];

  return (
    <div className='flex flex-1 flex-col items-end gap-4 self-end tablet:basis-1/2'>
      {logosText ? (
        <div
          className={clsx(
            'text-xs font-normal leading-[18.6px] tracking-[0.24px]',
            themeStyles.logosTextColor,
          )}
        >
          {logosText}
        </div>
      ) : null}

      {logos?.length ? (
        <div className='flex flex-wrap justify-end'>
          {logos.map((logo, index) => (
            <span
              key={index}
              className={clsx(
                'flex h-[72px] justify-center border-e border-t px-6',
                themeStyles.logoBorderColor,
              )}
            >
              <Image {...logo} className='max-h-full' />
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};
