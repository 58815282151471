import {
  Container,
  EContainerVariant,
  TitleV2,
  TTitleV2Props,
  ETitleV2Theme,
  EEyebrowMarker,
  toRichText,
  toEnum,
  toImageProps,
} from '@front/shared/ds';
import React, { FC } from 'react';

import type { Section } from '@shared/master-types';
import type { ImageProps } from 'next/image';

export type TTitleV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  {
    blockType: 'title-v2';
  }
>;

export const TitleV2Connected: FC<TTitleV2ConnectedProps> = props => {
  const {
    eyebrow,
    title,
    subTitle,
    theme,
    logos = [],
    logosText,
    eyebrowMarker,
    eyebrowMarkerColor,
  } = props;

  const preparedData: TTitleV2Props = {
    eyebrow,
    title: toRichText(title),
    subTitle: toRichText(subTitle),
    theme: toEnum(ETitleV2Theme, theme),
    hasEyebrowMarker: eyebrowMarker === EEyebrowMarker.Yes,
    eyebrowMarkerColor,
    logosText,
    logos: logos
      .map(logo => toImageProps(logo.image))
      .filter(Boolean) as ImageProps[],
  };

  return (
    <Container
      variant={EContainerVariant.Full}
      className='max-w-[462px] tablet:max-w-full'
    >
      <TitleV2 {...preparedData} />
    </Container>
  );
};

export default TitleV2Connected;
